import { createSlice } from '@reduxjs/toolkit'

export const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState: {
    open: false,
    message: ''
  },
  reducers: {
    closeSnackBar: (state, _action) => {
      state.open = false
    },
    showMessage: (state, action) => {
      state.open = true
      state.message = action.payload
    }
  }
})

export const { closeSnackBar, showMessage } = snackBarSlice.actions

export const getIsOpen = state => state.snackBar.open
export const getMessage = state => state.snackBar.message

export default snackBarSlice.reducer
