import React, { useState, useEffect } from 'react'
import UploadLogo from './images/upload_logo.png'
import AddOrEditStoryDialog from './components/story/AddOrEditStoryDialog'
import AddUserDialog from './components/user/AddUserDialog'
import EditAppDialog from './components/apps/EditAppDialog'
import Util from './Util'
import ProgressDialog from './components/util/ProgressDialog'
import Snackbar from './components/util/Snackbar'
import GrayArrow from './images/gray_arrow.png'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import DeleteConfirmationDialog from './components/util/DeleteConfirmationDialog'
import ReviewUserSubmittedContent from './components/apps/ugc/ReviewUserSubmittedContent'
import Title from './components/apps/Title'
import Control from './components/apps/Control'
import Filter from './components/apps/Filter'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Search from './components/collection/Search'
import SortableStoryList from './components/story/SortableStoryList'
import lodash from 'lodash'
import PlatformTop from './components/UI/PlatformTop'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { showMessage } from './store/snackBarSlice'
import { deleteExhibit, isAdmin } from './store/exhibitSlice'
import { loadMapStyles } from './store/mapStyleSlice'
import { connect, useSelector } from 'react-redux'
import { AddImageUrlPage } from './components/gameday/AddImageUrlPage'
import adminApi from './store/exhibitAdminApi'
import clsx from 'clsx'
import CollectionDialog from './components/collection/CollectionDialog'

const mapStateToProps = (state) => ({
  mapStyles: state.mapstyle.all
})
const mapDispatchToProps = { showMessage, deleteExhibit, loadMapStyles }

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '15px 2%',
    width: '100%',
    background: '#f1f1f1',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },
  editCollection: {
    position: 'absolute',
    bottom: 40,
    right: 10,
    color: 'white',
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
    },
  },
  linearGradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: '.8',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%)',
    height: 100,
  },
  collectionsFilter: {
    backgroundImage: `url(${GrayArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    maxWidth: 250,
    float: 'left',
    margin: '10px 10px 0 0',
    paddingRight: 40,
  },
  backToAppsArrow: {
    borderWidth: '0 6px 6px 0 !important',
    display: 'inline-block',
    padding: 6,
    transform: 'rotate(135deg)',
    webkitTransform: 'rotate(135deg)',
    cursor: 'pointer',
  },
  platformTop: {
    width: '100%',
    float: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

function AllCollectionsPage(props) {
  const { org, user } = props
  const { appId } = props.match.params

  const classes = useStyles()
  const theme = useTheme()

  const DEFAULT_SELECTED_COLLECTION_STATE = {
    id: '',
    title: 'All Collections',
    description: '',
    coverPhoto: '',
    stories: [],
    subCollections: [],
    featured: false,
  }

  const DEFAULT_COLLECTION_STATE = {
    id: '',
    title: 'All Collections',
    description: '',
    coverPhoto: '',
    stories: [],
    subCollections: [],
    featured: false,
  }

  const [openAddOrEditStoryDialog, setOpenAddOrEditStoryDialog] = useState(false)
  const [openCollectionDialog, setOpenCollectionDialog] = useState(false)
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [orderBy, setOrderBy] = useState('all')
  const [openEditAppDialog, setOpenEditAppDialog] = useState(false)
  const [app, setApp] = useState(undefined)
  const [collections, setCollections] = useState([ DEFAULT_COLLECTION_STATE ])
  const [stories, setStories] = useState([])
  const [allStories, setAllStories] = useState([])
  const [storyToEdit, setStoryToEdit] = useState(undefined)
  const [imageUrlStoryToEdit, setImageUrlStoryToEdit] = useState(undefined)
  const [selectedCollection, setSelectedCollection] = useState(DEFAULT_SELECTED_COLLECTION_STATE)
  const [showProgress, setShowProgress] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [collectionToDelete, setCollectionToDelete] = useState(undefined)
  const [platformLogo, setPlatformLogo] = useState(UploadLogo)
  const [deleteDialogName, setDeleteDialogName] = useState(undefined)
  const [deleteDialogType, setDeleteDialogType] = useState(undefined)
  const [openReviewSubmittedContentDialog, setOpenReviewSubmittedContentDialog] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [backArrowColor, setBackArrowColor] = useState(theme.palette.secondary.main)
  const [offset, setOffset] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [openAddImageUrlPage, setOpenAddImageUrlPage] = useState(false)
  const [showImageUrl, setShowImageUrl] = useState(false)


  const isExhibitAdmin = useSelector(isAdmin)

  const trackScrolling = () => {
    setShowProgress(true)
    updateOffset(offset)
  }

  useBottomScrollListener(trackScrolling, { offset: 150 })

  const fetchStories = async (data) => {
    const { appId, collection={}, offset=0, searchTerm='', orderBy='all'} = data

    if (fetching) {
      return
    }

    setFetching(true)

    const paramOffset = offset > stories.length ? stories.length : offset
    const collectionId = collection?.id || ''

    const fetchedStories = await adminApi.story().search({ exhibitId: appId, collectionId, term: searchTerm, orderBy, offset: paramOffset })

    if(fetchedStories.length > 0) {
      console.log('fetched length', fetchedStories.length)
      const result = offset === 0 ? fetchedStories : [...stories, ...fetchedStories]
      setStories(result)
    }

    setShowProgress(false)
    setFetching(false)
  }

  useEffect(() => {
    async function fetchAppDetails() {
      const app = await adminApi.exhibit().getById({ id: appId, template: true })
      setApp(app)
      setPlatformLogo(adminApi.image().imageUrl(app.image, 200, 200))
      setBackArrowColor(app.secondaryColor)

      if(Util.isGameDay(app)) setShowImageUrl(true)
    }

    async function fetchExhibitCollections() {
      const collectionsResult = await adminApi.exhibit().getCollections(appId)
      setCollections([...collections, ...collectionsResult])
    }

    props.loadMapStyles()
    fetchAppDetails()
    fetchExhibitCollections()

  }, [appId])

  useEffect(() => {
    fetchStories({ appId, collection: selectedCollection, offset, searchTerm, orderBy })
  }, [appId, selectedCollection, offset, searchTerm, orderBy])

  // debouncing onScroll since the function is called too much when
  // user scrolls to the bottom
  const updateOffset = lodash.debounce(prevOffset => {
    setOffset(prevOffset + 20)
  }, 1000)


  const handleClickStory = (storyId) => () => {
    const storyToEdit = stories.find(story => story.id === storyId)
    if (!storyToEdit) {
      console.error('could not find story')
      return
    }

    const storyCopy = { ...storyToEdit }

    if(storyCopy.storyType === 'imageUrl'){
      setImageUrlStoryToEdit(storyCopy)
      setOpenAddImageUrlPage(true)
    }else{
      setStoryToEdit(storyCopy)
      setOpenAddOrEditStoryDialog(true)
    }
  }

  const handleNewStory = (story) => {
    const storiesCopy = [...stories]
    storiesCopy.push(story)
    setStories(storiesCopy)

    const allStoriesCopy = [...allStories]
    allStoriesCopy.push(story)
    setAllStories(allStoriesCopy)
  }

  const handleEditedStory = (editedStory) => {
    const storiesCopy = [...stories]
    const index = storiesCopy.findIndex(story => story.id === editedStory.id)
    if(index === -1) {
      console.error('could not find story')
      showSnackBarMessage('Could not save story. Try again later.')
      return
    }

    storiesCopy[index] = editedStory
    setStories(storiesCopy)

    const allStoriesCopy = [...allStories]
    const allStoriesStoryIndex = allStoriesCopy.findIndex(story => story.id === editedStory.id)
    allStoriesCopy[allStoriesStoryIndex] = editedStory
    setAllStories(allStoriesCopy)
  }

  const handleCloseAddOrEditStoryDialog = () => {
    setStoryToEdit(undefined)
    setOpenAddOrEditStoryDialog(false)
  }

  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false)
  }

  const handleCloseEditAppDialog = () => {
    setOpenEditAppDialog(false)
  }

  const handleEditedApp = (editedApp) => {
    setApp(editedApp)
    setPlatformLogo(editedApp.image)

    const shouldShowImageUrl = Util.isGameDay(editedApp)
    setShowImageUrl(shouldShowImageUrl)

    setShowProgress(false)
    showSnackBarMessage('Updated exhibit')
  }

  const handleSaveCollection = async (collection) => {
    adminApi.collection().save([collection])

    let copy = lodash.cloneDeep(collections)
    const findCollection = copy.find(c => c.id === collection.id)
    if(findCollection) {
      // Editing collection
      const index = copy.findIndex(c => c.id === collection.id)
      copy[index] = collection

      setSelectedCollection(collection)
    } else {
      // New collection
      copy = lodash.concat(copy, collection)

      if(selectedCollection !== DEFAULT_SELECTED_COLLECTION_STATE) {
        setSelectedCollection(DEFAULT_SELECTED_COLLECTION_STATE)
      }
    }

    setCollections(copy)
    setShowProgress(false)
    showSnackBarMessage('Saved successfully')
  }

  const handleCloseCollectionDialog = () => {
    setOpenCollectionDialog(false)
    setSelectedCollection(DEFAULT_SELECTED_COLLECTION_STATE)
  }

  const handleSetOrderBy = (orderType) => {
    setOrderBy(orderType)
    setStories([])
    fetchStories({appId, collection: selectedCollection, offset: 0, searchTerm, orderBy: orderType})
  }

  const handleViewExhibit = () => {
    const exhibitId = props.match.params.appId
    let link

    if(Util.isGameDay(app)){
      link = `/gameday/${encodeURIComponent(app.name)}`
    }else{
      link = `/present/${exhibitId}/${app.template.name}`
    }

    Util.openInNewTab(link)
  }

  const handleCollectionFilterChange = (e) => {
    const value = e.target.value

    setStories([])
    setShowProgress(true)
    setOffset(0)

    if (value === 'All Collections') {
      setSelectedCollection(DEFAULT_SELECTED_COLLECTION_STATE)
      fetchStories({appId, collection: undefined, offset: 0, searchTerm, orderBy})
    } else {
      const collection = collections.find(collection => collection.title === value)
      fetchStories({appId, collection, offset: 0, searchTerm, orderBy})
      setSelectedCollection(collection)
    }
  }

  const showSnackBarMessage = (message) => {
    props.showMessage(message)
  }

  const handleShowDeleteCollectionConfirmationDialog = () => {
    setShowDeleteDialog(true)
    setCollectionToDelete(selectedCollection)
    setDeleteDialogName(selectedCollection?.title ?? '')
    setDeleteDialogType('Collection')
  }

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false)
    setCollectionToDelete(undefined)
  }

  const handleDeleteCollection = async () => {
    setShowDeleteDialog(false)
    setShowProgress(true)

    await deleteCollection()

    setShowProgress(false)
    showSnackBarMessage('Deleted collection')
    setOpenCollectionDialog(false)

    const collectionsCopy = [...collections]
    if (!collectionToDelete) {
      console.error('collection to delete is empty')
      return
    }

    const index = collectionsCopy.findIndex(c => c.id === collectionToDelete.id)

    if (index === -1) {
      console.error('could not find index')
      return
    }

    collectionsCopy.splice(index, 1)
    setCollections(collectionsCopy)

    setCollectionToDelete(undefined)

    setSelectedCollection(DEFAULT_SELECTED_COLLECTION_STATE)
    setStories(allStories)

    setDeleteDialogName(undefined)
    setDeleteDialogType(undefined)
  }

  const handleDeleteExhibit = async () => {
    setShowDeleteDialog(false)
    setOpenEditAppDialog(false)
    setShowProgress(true)

    const success = await props.deleteExhibit(appId)
    if(success) {
      showSnackBarMessage('Exhibit deleted')
      props.history.goBack()
    }
    else {
      setShowProgress(false)
      showSnackBarMessage('Failed to delete exhibit')
    }
  }

  const handleDeleteConfirmed = async () => {
    deleteDialogType === 'Collection' ?  handleDeleteCollection() : handleDeleteExhibit()
  }

  const deleteCollection = async () => {
    if (!collectionToDelete) {
      console.error('collectionToDelete is empty')
      return
    }

    await adminApi.collection().delete({ collectionId: collectionToDelete.id, exhibitId: appId })
  }

  const handleDeleteExhibitClicked = () => {
    setDeleteDialogName(app?.name ?? '')
    setDeleteDialogType('exhibit')
    setShowDeleteDialog(true)
  }

  const handleStoryDeleted = (storyId) => {
    setShowProgress(false)
    showSnackBarMessage('Story Deleted')
    const storyIndex = allStories.findIndex(s => s.id === storyId)
    const copy1 = [...allStories]
    copy1.splice(storyIndex, 1)
    setAllStories(copy1)

    const storyIndex2 = stories.findIndex(s => s.id === storyId)
    if (storyIndex2 !== -1) {
      const copy2 = [...stories]
      copy2.splice(storyIndex2, 1)
      setStories(copy2)
    }

    setStoryToEdit(undefined)
  }

  const handleCloseReviewSubmittedContentDialog = () => {
    setOpenReviewSubmittedContentDialog(false)
    fetchStories({appId, collection: selectedCollection, offset: 0})
  }

  const handleControlClick = (type) => {
    switch (type) {
      case 'edit':
        setOpenEditAppDialog(true)
        break
      case 'add-user':
        setOpenAddUserDialog(true)
        break
      case 'add-story':
        setOpenAddOrEditStoryDialog(true)
        break
      case 'add-collection':
        setOpenCollectionDialog(true)
        break
      case 'add-image-url':
        setOpenAddImageUrlPage(true)
        break
      case 'review-user-submitted-content':
        setOpenReviewSubmittedContentDialog(true)
        break
      case 'view-exhibit':
        handleViewExhibit()
        break
      default:
        console.error('error')
    }
  }

  const handleSearchStories = (searchTerm) => {
    setSearchTerm(searchTerm)
    setOffset(0)
    setStories([])
    fetchStories({appId, collection: selectedCollection, offset: 0, searchTerm, orderBy})
  }

  const handleBackArrowClicked = () => {
    props.history.goBack()
  }

  const handleCloseAddImageUrlPage = () => {
    setOpenAddImageUrlPage(false)
    setImageUrlStoryToEdit(undefined)
  }


  const disabled = orderBy === 'all' ? false : true

  const coverPhotoUrl = adminApi.image().imageUrl(selectedCollection.coverPhoto, 750, 750)

  return (
    <ThemeProvider
      theme={
        app
          ? createTheme({
              palette: {
                primary: { main: app.mainColor },
                secondary: { main: app.secondaryColor },
              },
              subTitle: {
                fontSize: 18,
              },
            })
          : theme
      }
    >
      <div className={classes.root}>
        <div>
          <div style={{ alignItems: 'center', margin: 10 }}>
            <i
              style={{ border: `solid ${backArrowColor}` }}
              className={classes.backToAppsArrow}
              onClick={handleBackArrowClicked}
            ></i>
          </div>
          <div style={{ display: 'flex', flexGrow: 1 }}>
            <div
              className='platform_logo platform_left mobilehide'
              style={{
                background: `url(${platformLogo})center center/contain no-repeat #fff`,
              }}
            />
            <PlatformTop className={classes.platformTop}>
              <Title app={app} />
              <div>
                <Control
                  show={isExhibitAdmin}
                  type='edit'
                  text='Edit'
                  click={handleControlClick}
                />
                <Control
                  show={isExhibitAdmin}
                  type='add-user'
                  text='+ User'
                  click={handleControlClick}
                />
                <Control
                  show={true}
                  type='add-story'
                  text='+ Story'
                  click={handleControlClick}
                />
                <Control
                  show={true}
                  type='add-collection'
                  text='+ Collection'
                  click={handleControlClick}
                />

                <Control
                  show={showImageUrl}
                  type='add-image-url'
                  text='+ Image URL'
                  click={handleControlClick}
                />

                <Control
                  show={isExhibitAdmin && app?.userGeneratedContentAllowed}
                  type='review-user-submitted-content'
                  text='Review User Submitted Content'
                  click={handleControlClick}
                />
                <Control
                  show={true}
                  type='view-exhibit'
                  text='View Exhibit &raquo;'
                  click={handleControlClick}
                />
              </div>
            </PlatformTop>
          </div>
        </div>

        <div className='admin_editor m10' style={{ cursor: 'pointer', }}>
          <div id='adminbottom'>
            <Filter
              leftMost={true}
              rightMost={false}
              orderBy={orderBy}
              id={'all'}
              name={'All Stories'}
              click={handleSetOrderBy}
            />
            <Filter
              leftMost={false}
              rightMost={false}
              orderBy={orderBy}
              id={'active'}
              name={'Active'}
              click={handleSetOrderBy}
            />
            <Filter
              leftMost={false}
              rightMost={false}
              orderBy={orderBy}
              id={'most-recent'}
              name={'Most Recent'}
              click={handleSetOrderBy}
            />
            <Filter
              leftMost={false}
              rightMost={true}
              orderBy={orderBy}
              id={'oldest'}
              name={'Oldest'}
              click={handleSetOrderBy}
            />
          </div>
        </div>

        <select
          value={selectedCollection.title}
          className={clsx('roundform', classes.collectionsFilter)}
          onChange={handleCollectionFilterChange}>
          {collections.map((collection) => (
            <option key={collection.title} value={collection.title}>
              {collection.title}
            </option>
          ))}
        </select>
      </div>

      <div
        style={{
          display:
            selectedCollection && selectedCollection.title === 'All Collections' ? 'none' : 'block',
          background: `url(${coverPhotoUrl})center center/cover no-repeat`,
          width: '100%',
          position: 'relative',
          height: 300,
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '1%',
            fontSize: 32,
            textShadow: '0 2px 4px #222',
            bottom: 40,
            borderBottom: `3px solid ${
              app ? app.mainColor : theme.palette.primary.main
            }`,
            color: 'white',
            zIndex: 1,
          }}
        >
          {selectedCollection && selectedCollection.title}
        </div>

        <button
          className={classes.editCollection}
          style={{ backgroundColor: app ? app.secondaryColor : '#39f' }}
          onClick={() => setOpenCollectionDialog(true)}>
          Edit Collection
        </button>

        <div className={classes.linearGradient} />
      </div>

      <div
        style={{
          marginTop: '1%',
          marginBottom: '1%',
        }}
      >
        <Search
          placeholder={'Press Enter to Search Stories'}
          search={handleSearchStories}
        />
        <SortableStoryList
          app={app}
          disabled={disabled}
          stories={stories}
          handleClickStory={handleClickStory}
          fetching={fetching}
         />
      </div>

      <AddOrEditStoryDialog
        match={props.match}
        orgId={props.match.params.orgId}
        app={app}
        open={openAddOrEditStoryDialog}
        editedStory={handleEditedStory}
        newStory={handleNewStory}
        story={storyToEdit}
        stories={stories}
        showMessage={showSnackBarMessage}
        toggleProgress={() => setShowProgress(!showProgress)}
        handleClose={handleCloseAddOrEditStoryDialog}
        resetStoryToEdit={() => setStoryToEdit(undefined)}
        storyDeleted={handleStoryDeleted}
      />

      <AddImageUrlPage
        open={openAddImageUrlPage}
        orgId={props.match.params.orgId}
        app={app}
        stories={stories}
        newStory={handleNewStory}
        storyToEdit={imageUrlStoryToEdit}
        editedStory={handleEditedStory}
        close={handleCloseAddImageUrlPage}
        showMessage={showSnackBarMessage}
        toggleProgress={() => setShowProgress(!showProgress)}
        storyDeleted={handleStoryDeleted} />

      <CollectionDialog
        collection={selectedCollection}
        org={org}
        app={app}
        toggleProgress={() => setShowProgress(!showProgress)}
        showMessage={showSnackBarMessage}
        close={handleCloseCollectionDialog}
        saveCollection={handleSaveCollection}
        deleteCollection={handleShowDeleteCollectionConfirmationDialog}
        open={openCollectionDialog}
      />

      <AddUserDialog
        orgId={props.match.params.orgId}
        open={openAddUserDialog}
        showMessage={showSnackBarMessage}
        appId={appId}
        app={app}
        toggleProgress={() => setShowProgress(!showProgress)}
        handleClose={handleCloseAddUserDialog}
      />

      <EditAppDialog
        org={org}
        app={app}
        user={user}
        mapStyles={props.mapStyles}
        toggleProgress={() => setShowProgress(!showProgress)}
        handleEditedApp={handleEditedApp}
        open={openEditAppDialog}
        handleDelete={handleDeleteExhibitClicked}
        handleClose={handleCloseEditAppDialog}
        showMessage={showSnackBarMessage} />

      <DeleteConfirmationDialog
        open={showDeleteDialog}
        close={handleCloseDeleteDialog}
        name={deleteDialogName}
        deleteConfirmed={handleDeleteConfirmed}
      />

      <ProgressDialog show={showProgress} />

      <Snackbar />

      <ReviewUserSubmittedContent
        open={openReviewSubmittedContentDialog}
        close={handleCloseReviewSubmittedContentDialog}
        app={app}
        stories={stories}
      />
    </ThemeProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCollectionsPage)
