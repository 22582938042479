// TODO : admin text
// TODO : user image

import React from 'react'
import DefaultUserPhoto from '../../images/user_default_photo.jpg'
import UserContext from '../../context/UserContext'
import { makeStyles, useTheme } from '@material-ui/core'


const styles = makeStyles({
  userName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

function User(props) {
  const { user, showUserDetails } = props

  const theme = useTheme()
  const classes = styles()

  const context = React.useContext(UserContext)
  const localUser = context
  const localUserEmail = localUser.email

  return (
    <div className="platform_tile" onClick={() => showUserDetails(user._id)}>
      <div
        className="platform_tile_logo"
        style={{
          position: 'relative',
          background: `url(${DefaultUserPhoto})center center/contain no-repeat`,
        }}
      />

      <div className={`platform_tile_name ${theme.palette.primary.main} ${classes.userName}`}>
        {user.name ? user.name : user.email}
        {user.email === localUserEmail ? ', (Me)' : ''}
      </div>
    </div>
  )
}

export default User
